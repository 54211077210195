import { useState, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import _ from 'underscore';

import { getDate, getTime } from './../../utils/date';
import { getIcon } from './../../utils/fileTypes';
import { addModel, removeModel, startAnalysis } from './../../utils/forge';
import {
    IcAdd,
    IcAddFill,
    IcEye,
    IcEyeFill,
    IcObjectDate,
    IcObjectName,
    IcObjectVersion,
    IcRemove,
    IcRemoveFill,
    IcDelete,
    IcDeleteFill,
    IcDownload,
    IcLoading,
    IcRadar,
    IcModalClose,
    IcModalBack,
    IcModalConfirm,
} from './../../share/icons';
import { mainContext } from './../context/mainContext';
import locale from '../../utils/localization.json';
import ObjectCardItem from './ObjectCardItem';
import {
    getAllPointClouds,
    handlePointCloudDelete,
    updatePointCloudStatus,
} from './../../actions/pointcloud';
import { cancelAnalysis } from '../../services/pointcloudService';
import { updateNotifications } from '../../actions/viewer';
import { errorMessage } from '../../utils/message';
import Modal from './../../share/components/base/Modal';
import { setCurrentModel } from '../../actions/model';

const ObjectCard = ({ object, isModel }) => {
    const { urn, filename, name, type, status, date, version, url } = object;

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);
    const {
        permissions: { point_cloud_analyze: analysis },
    } = useSelector((state) => state.currentProject);
    const currentObject = useSelector((state) => state.currentObject);

    const {
        loadModel,
        handlePointClouds,
        currentLinks,
        setCurrentLinks,
        loadedPointClouds,
        deletePointCloud,
    } = useContext(mainContext);

    const [waiting, setWaiting] = useState(false);
    const [, forceUpdate] = useState();
    const [loading, setLoading] = useState(false);
    const [min, setMin] = useState(1);
    const [max, setMax] = useState(20);
    const [hidden, setHidden] = useState(false);
    const [shown, setShown] = useState(false);
    const [showDistance, setShowDistance] = useState(
        loadedPointClouds?.find((p) => p.filename === filename)?.showDistance ||
            false
    );
    const [showConfig, setShowConfig] = useState(false);
    const [ranges, setRanges] = useState([{ min: 0, max: 20, title: '0-20' }]);
    const [percentages, setPercentages] = useState({});

    const dispatch = useDispatch();

    const ref = useRef(null);
    const modalRef = useRef(null);

    useEffect(() => {
        if (
            loadedPointClouds?.find((p) => p.filename === filename) &&
            loadedPointClouds?.find((p) => p.filename === filename).isLoaded ===
                undefined
        )
            setLoading(true);
        else setLoading(false);

        if (
            loadedPointClouds?.find((p) => p.filename === filename)
                ?.isLoaded !== undefined
        )
            setHidden(true);
        else setHidden(false);

        if (loadedPointClouds?.find((p) => p.filename === filename)?.isLoaded)
            setShown(true);
        else setShown(false);

        return () => forceUpdate(true);
    }, [loadedPointClouds, filename]);

    useEffect(() => {
        if (
            shown &&
            loadedPointClouds?.find((p) => p.filename === filename)
                ?.isLoaded !== undefined
        ) {
            ref.current.click();
            setTimeout(() => ref.current.click(), 100);
        }

        if (showDistance) {
            const extension = viewer?.getExtension('PointCloudExtension');
            if (extension) {
                const points = extension['POINTCLOUD_' + filename];
                if (points)
                    setPercentages(
                        _.countBy(points, ({ D }) => {
                            const theRange = ranges.find(
                                (r) => D > r.min * 10 && D <= r.max * 10
                            );
                            return theRange
                                ? theRange.title
                                : D > 200
                                ? '>20'
                                : '';
                        })
                    );
            }
        } else {
            Object.keys(percentages).forEach((p) =>
                dispatch(
                    updateNotifications(
                        'percentages_' + name + '_' + type + '_' + p
                    )
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDistance]);

    useEffect(() => {
        const extension = viewer?.getExtension('PointCloudExtension');
        if (extension) {
            const points = extension['POINTCLOUD_' + filename];
            if (points)
                Object.keys(percentages)
                    .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
                    .forEach(
                        (p) =>
                            p &&
                            dispatch(
                                updateNotifications(
                                    'percentages_' +
                                        name +
                                        '_' +
                                        type +
                                        '_' +
                                        p,
                                    `${name} : ${p} cm : ${Math.round(
                                        (percentages[p] / points.length) * 100
                                    )}%`
                                )
                            )
                    );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percentages]);

    return (
        <div
            className={`w-full pr-4 pl-2 py-4 ${
                loading ? 'pb-3' : ''
            } flex flex-col bg-modelic-gray-800 rounded border border-modelic-gray-700`}
        >
            <div className="flex justify-between">
                <div className="max-w-[calc(100%-57px)] flex-col inline-flex gap-2">
                    <ObjectCardItem
                        icon={IcObjectName}
                        title={locale[lang].name}
                        component={
                            <div className="truncate text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                                {name}.{type}
                            </div>
                        }
                    />
                    <ObjectCardItem
                        icon={IcObjectDate}
                        title={locale[lang].date}
                        component={
                            <div className="text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                                {getDate(date)} - {getTime(date)}
                            </div>
                        }
                    />
                </div>
                <div>{getIcon(type)}</div>
            </div>
            <div
                className={`flex flex-wrap ${
                    isModel ? 'mt-[7px]' : analysis || !loading ? 'mt-2' : ''
                }`}
            >
                {isModel ? (
                    <ObjectCardItem
                        icon={IcObjectVersion}
                        title={locale[lang].version}
                        component={
                            <div className="px-2 pt-1 pb-0.5 bg-modelic-info-darker rounded-lg border border-modelic-info-main">
                                <div className="text-modelic-info-main text-xs font-normal font-poppins content-end">
                                    V{version}
                                </div>
                            </div>
                        }
                    />
                ) : analysis ? (
                    <div className="h-5">
                        <ObjectCardItem
                            icon={IcRadar}
                            title={
                                status !== 'analyzed' && locale[lang].analysis
                            }
                            component={
                                status === 'inprogress' ? (
                                    <>
                                        <div className="text-modelic-gray-500 text-xs font-medium font-poppins leading-3">
                                            {locale[lang].inProgress}
                                        </div>
                                        <button
                                            className="text-modelic-gray-500 text-xs font-medium font-poppins leading-3 hover:scale-105 hover:text-modelic-warning-light"
                                            onClick={async () => {
                                                try {
                                                    await cancelAnalysis(
                                                        {
                                                            name: `${name}_${type}.txt`,
                                                        },
                                                        currentObject._id +
                                                            currentObject.version
                                                    );
                                                } catch {
                                                    // ignore
                                                } finally {
                                                    dispatch(
                                                        getAllPointClouds()
                                                    );
                                                }
                                            }}
                                        >
                                            ({locale[lang].cancel})
                                        </button>
                                    </>
                                ) : status === 'analyzed' ? (
                                    <>
                                        <div className="text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                                            {locale[lang].rgb}
                                        </div>
                                        <label
                                            className={`relative inline-flex items-center ${
                                                hidden ? 'cursor-pointer' : ''
                                            }`}
                                        >
                                            <input
                                                id="switch-3"
                                                type="checkbox"
                                                className="peer sr-only"
                                                disabled={!hidden}
                                                checked={showDistance}
                                                onChange={(e) => {
                                                    if (e.target.checked)
                                                        setShowConfig(true);
                                                    else setShowDistance(false);
                                                }}
                                            />
                                            <label
                                                htmlFor="switch-3"
                                                className="hidden"
                                            ></label>
                                            <div className="peer h-3 w-10 rounded border bg-modelic-gray-700 after:absolute after:-top-1 after:left-0 after:h-5 after:w-5 after:rounded-md after:border after:border-modelic-gray-700 after:bg-modelic-gray-100 after:transition-all after:content-[''] peer-checked:bg-modelic-primary-light peer-checked:after:translate-x-full peer-focus:ring-modelic-primary-light"></div>
                                        </label>
                                        <div className="text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                                            {locale[lang].distance}
                                        </div>
                                        <Modal
                                            lable={
                                                <div className="text-modelic-gray-500 text-xs font-medium font-poppins leading-3 hover:scale-105 hover:text-modelic-error-dark">
                                                    ({locale[lang].clear})
                                                </div>
                                            }
                                            title={
                                                locale[lang].analysisClearTitle
                                            }
                                            tag="delete"
                                            callback={async () => {
                                                try {
                                                    const currentModel =
                                                        currentObject;
                                                    await dispatch(
                                                        setCurrentModel(object)
                                                    );
                                                    await dispatch(
                                                        handlePointCloudDelete()
                                                    );
                                                    await dispatch(
                                                        setCurrentModel(
                                                            currentModel
                                                        )
                                                    );
                                                } catch (err) {
                                                    // ignore
                                                }
                                            }}
                                        >
                                            <div
                                                ref={modalRef}
                                                className="text-lg"
                                            >
                                                {locale[lang].analysisClearBody}{' '}
                                                "{name}"?
                                            </div>
                                        </Modal>
                                    </>
                                ) : status === 'waiting' ? (
                                    <div className="text-modelic-gray-500 text-xs font-medium font-poppins leading-3">
                                        {locale[lang].waiting} ...
                                    </div>
                                ) : (
                                    <button
                                        className="text-modelic-primary-light text-xs font-medium font-poppins leading-3 hover:scale-105"
                                        onClick={async () => {
                                            try {
                                                dispatch(
                                                    updatePointCloudStatus(
                                                        filename
                                                    )
                                                );
                                                await startAnalysis(
                                                    viewer,
                                                    filename,
                                                    currentObject._id +
                                                        currentObject.version
                                                );
                                            } catch (err) {
                                                // console.log(err);
                                            } finally {
                                                dispatch(getAllPointClouds());
                                            }
                                        }}
                                    >
                                        {locale[lang].start}
                                    </button>
                                )
                            }
                        />
                    </div>
                ) : null}
                <div
                    className={`flex ${
                        loading
                            ? 'justify-between w-full'
                            : 'justify-end mt-[3px]'
                    } self-end grow`}
                >
                    {loading && (
                        <div className="items-center flex">
                            <div className="text-modelic-primary-lighter scale-90 mr-1.5">
                                {IcDownload}
                            </div>
                            <div className="text-modelic-primary-lighter text-xs font-normal font-poppins leading-3 mr-1.5">
                                {locale[lang].progress}:
                            </div>
                            <div className="text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                                {loadedPointClouds?.find(
                                    (p) => p.filename === filename
                                )?.progress || 0}{' '}
                                %
                            </div>
                            <div className="scale-50 text-modelic-primary-main">
                                <div className="animate-spin">{IcLoading}</div>
                            </div>
                        </div>
                    )}
                    <div className="flex gap-4">
                        {isModel && (
                            <button
                                className={`text-modelic-primary-lighter ${
                                    !waiting ? 'group hover:scale-105' : ''
                                }`}
                                disabled={isModel && waiting}
                                onClick={async () => {
                                    if (isModel) setWaiting(true);

                                    try {
                                        if (!isEmpty(viewer))
                                            loadModel(viewer, object, 0);
                                    } finally {
                                        setWaiting(false);
                                    }
                                }}
                            >
                                <>
                                    {IcEye}
                                    {IcEyeFill}
                                </>
                            </button>
                        )}
                        {viewer?.model?.getDocumentNode()?.data?.role ===
                            '3d' && (
                            <>
                                {hidden && (
                                    <button
                                        className="group hover:scale-105 text-modelic-error-dark"
                                        onClick={() => {
                                            setShowDistance(false);
                                            deletePointCloud(filename);
                                        }}
                                    >
                                        <>
                                            {IcDelete}
                                            {IcDeleteFill}
                                        </>
                                    </button>
                                )}
                                {status !== 'inprogress' && (
                                    <button
                                        ref={ref}
                                        className={`font-poppins text-xs font-medium ${
                                            !waiting
                                                ? 'group hover:scale-105'
                                                : ''
                                        } ${
                                            currentLinks?.find(
                                                (link) => link.urn === urn
                                            ) || shown
                                                ? 'text-modelic-error-dark'
                                                : loading
                                                ? 'text-modelic-gray-500'
                                                : 'text-modelic-primary-main'
                                        }`}
                                        disabled={isModel && waiting}
                                        onClick={async () => {
                                            try {
                                                if (isModel) {
                                                    setWaiting(true);
                                                    const linkIndex =
                                                        currentLinks.findIndex(
                                                            (link) =>
                                                                link.urn === urn
                                                        );

                                                    if (linkIndex >= 0) {
                                                        removeModel(
                                                            currentLinks[
                                                                linkIndex
                                                            ].model,
                                                            viewer
                                                        );
                                                        const filteredLinks =
                                                            currentLinks.filter(
                                                                (link) =>
                                                                    link.urn !==
                                                                    urn
                                                            );

                                                        setCurrentLinks([
                                                            ...filteredLinks,
                                                        ]);
                                                    } else {
                                                        const model =
                                                            await addModel(
                                                                urn,
                                                                viewer
                                                            );

                                                        setCurrentLinks([
                                                            ...currentLinks,
                                                            { urn, model },
                                                        ]);
                                                    }
                                                } else {
                                                    await handlePointClouds(
                                                        filename,
                                                        name,
                                                        showDistance,
                                                        min,
                                                        max,
                                                        url
                                                    );
                                                }
                                            } catch {
                                            } finally {
                                                setWaiting(false);
                                            }
                                        }}
                                    >
                                        {currentLinks?.find(
                                            (link) => link.urn === urn
                                        ) || shown ? (
                                            <>
                                                {IcRemove}
                                                {IcRemoveFill}
                                            </>
                                        ) : loading ? (
                                            locale[lang].stop
                                        ) : (
                                            <>
                                                {IcAdd}
                                                {IcAddFill}
                                            </>
                                        )}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {showConfig && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-poppins">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*container*/}
                            <div className="border-0 rounded-lg relative flex flex-col w-full bg-modelic-gray-900 outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 rounded-t">
                                    <h3 className="text-2xl font-medium text-modelic-gray-100">
                                        {locale[lang].config}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-modelic-gray-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowConfig(false)}
                                    >
                                        <span className="bg-transparent text-modelic-gray-100 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            {IcModalClose}
                                        </span>
                                    </button>
                                </div>
                                {/*content*/}
                                <div className="flex">
                                    <div className="p-6 flex-auto text-modelic-gray-400">
                                        <div>{locale[lang].distanceMinMax}</div>
                                        <div className="text-xs mb-2">
                                            ({locale[lang].distanceMinMaxRange})
                                        </div>
                                        <div className="flex text-lg align-middle mb-2">
                                            {locale[lang].min} (cm)
                                            <input
                                                id="analysis-min"
                                                className="h-8 ml-2 p-2 w-20 outline-0 rounded border border-modelic-gray-700 bg-modelic-gray-900 text-modelic-gray-400 text-base font-medium font-poppins leading-8 self-center"
                                                type="number"
                                                onChange={(e) =>
                                                    setMin(e.target.value)
                                                }
                                                onKeyDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                value={min}
                                                min={1}
                                                max={19}
                                            />
                                        </div>
                                        <div className="flex text-lg align-middle">
                                            {locale[lang].max} (cm)
                                            <input
                                                id="analysis-max"
                                                className="h-8 ml-2 p-2 w-20 outline-0 rounded border border-modelic-gray-700 bg-modelic-gray-900 text-modelic-gray-400 text-base font-medium font-poppins leading-8 self-center"
                                                type="number"
                                                onChange={(e) =>
                                                    setMax(e.target.value)
                                                }
                                                onKeyDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                value={max}
                                                min={2}
                                                max={20}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-6 flex-auto text-modelic-gray-400">
                                        <div>
                                            {locale[lang].distancePercentage}
                                        </div>
                                        <div className="text-xs mb-2">
                                            (
                                            {
                                                locale[lang]
                                                    .distancePercentageRange
                                            }
                                            )
                                        </div>
                                        {ranges.map(({ min, max }, index) => (
                                            <div
                                                key={`ranges-${index}`}
                                                className="flex text-lg align-middle mb-2"
                                            >
                                                <input
                                                    className="h-8 p-2 w-20 outline-0 rounded border border-modelic-gray-700 bg-modelic-gray-900 text-modelic-gray-400 text-base font-medium font-poppins leading-8 self-center"
                                                    type="number"
                                                    onChange={(e) =>
                                                        setRanges((pre) => {
                                                            const updated = [
                                                                ...pre,
                                                            ];
                                                            updated[index].min =
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                );
                                                            updated[
                                                                index
                                                            ].title = `${updated[index].min}-${updated[index].max}`;
                                                            return updated;
                                                        })
                                                    }
                                                    onKeyDown={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    value={min}
                                                    min={
                                                        ranges[index - 1]
                                                            ? ranges[index - 1]
                                                                  .max
                                                            : 0
                                                    }
                                                    max={max}
                                                    step={0.1}
                                                />
                                                -
                                                <input
                                                    className="h-8 p-2 w-20 outline-0 rounded border border-modelic-gray-700 bg-modelic-gray-900 text-modelic-gray-400 text-base font-medium font-poppins leading-8 self-center"
                                                    type="number"
                                                    onChange={(e) =>
                                                        setRanges((pre) => {
                                                            const updated = [
                                                                ...pre,
                                                            ];
                                                            updated[index].max =
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                );
                                                            updated[
                                                                index
                                                            ].title = `${updated[index].min}-${updated[index].max}`;
                                                            return updated;
                                                        })
                                                    }
                                                    onKeyDown={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    value={max}
                                                    min={min}
                                                    max={
                                                        ranges[index + 1]
                                                            ? ranges[index + 1]
                                                                  .min
                                                            : 20
                                                    }
                                                    step={0.1}
                                                />
                                                {index > 0 && (
                                                    <button
                                                        className="ml-2 group"
                                                        onClick={() => {
                                                            const updated = [
                                                                ...ranges,
                                                            ];
                                                            updated.splice(
                                                                index,
                                                                1
                                                            );
                                                            setRanges(updated);
                                                        }}
                                                    >
                                                        {IcRemove}
                                                        {IcRemoveFill}
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                        {ranges.length < 10 && (
                                            <div className="flex text-sm align-middle">
                                                {locale[lang].add}
                                                <button
                                                    className="ml-2 group"
                                                    onClick={() => {
                                                        if (
                                                            ranges[
                                                                ranges.length -
                                                                    1
                                                            ].max >= 20
                                                        )
                                                            errorMessage(
                                                                locale[lang]
                                                                    .distancePercentageRangeMax
                                                            );
                                                        else
                                                            setRanges([
                                                                ...ranges,
                                                                {
                                                                    min: ranges[
                                                                        ranges.length -
                                                                            1
                                                                    ].max,
                                                                    max: 20,
                                                                    title: `${
                                                                        ranges[
                                                                            ranges.length -
                                                                                1
                                                                        ].max
                                                                    }-20`,
                                                                },
                                                            ]);
                                                    }}
                                                >
                                                    {IcAdd}
                                                    {IcAddFill}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="p-4 flex justify-center items-center space-x-8">
                                    <button
                                        className="flex justify-center items-center space-x-3 px-6 py-3 capitalize rounded hover:scale-105 text-modelic-primary-main"
                                        onClick={() => {
                                            setShowConfig(false);
                                            setShowDistance(false);
                                        }}
                                    >
                                        <span>{IcModalBack}</span>
                                        <span>{locale[lang].back}</span>
                                    </button>

                                    <button
                                        className="flex justify-center items-center space-x-3 px-6 py-3 capitalize rounded hover:scale-105 bg-modelic-primary-main text-modelic-gray-100"
                                        onClick={() => {
                                            setShowDistance(true);
                                            setShowConfig(false);
                                        }}
                                    >
                                        <span>{locale[lang].confirm}</span>
                                        <span>{IcModalConfirm}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        </div>
    );
};

export default ObjectCard;
