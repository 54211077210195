import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import locale from './../../utils/localization.json';
import ObjectCard from './ObjectCard';

const Object = ({ isModel }) => {
    const lang = useSelector((state) => state.language);
    const currentObject = useSelector((state) => state.currentObject);
    const viewer = useSelector((state) => state.viewer);
    const models = useSelector((state) => state.models);
    const pointclouds = useSelector((state) => state.pointclouds);

    const [sortedObjects, setSortedObjects] = useState([]);

    useEffect(() => {
        let objects = isModel
            ? [...models]
            : [
                  ...pointclouds.filter(
                      (p) =>
                          !p.modelId ||
                          p.modelId ===
                              currentObject._id + currentObject.version
                  ),
              ];
        setSortedObjects(() =>
            objects
                .sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
                .filter((object) => object.urn !== currentObject.urn)
        );
    }, [currentObject, models, pointclouds, isModel]);

    return (
        <div className="inline-flex flex-col gap-6 w-full">
            {sortedObjects.length === 0 ? (
                <div className="text-modelic-gray-100 self-center text-center">
                    {isModel ? locale[lang].noModel : locale[lang].noPointCloud}
                </div>
            ) : viewer?.model?.getDocumentNode()?.data?.role === '2d' &&
              !isModel ? (
                <div className="text-modelic-gray-100 self-center text-center">
                    {locale[lang].unableAddPointcloud}
                </div>
            ) : (
                sortedObjects.map((object) => (
                    <ObjectCard
                        key={object.urn || object.filename}
                        object={object}
                        isModel={isModel}
                    />
                ))
            )}
        </div>
    );
};

export default Object;
