import { getPublicToken } from './../services/forgeService';
import { analyzePointCloud } from './../services/pointcloudService';
import { errorMessage } from './message';
import locale from './../utils/localization.json';

export const getAccessToken = async (callback) => {
    try {
        const { data } = await getPublicToken();
        callback(data.access_token, data.expires_in);
    } catch (err) {
        // log.error(err);
    }
};

export const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'Failed to load manifest [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    if (viewerErrorCode === 9) {
        errorMessage([lang].onDocumentLoadFailureErrorCode9);
    } else {
        errorMessage(locale[lang].onDocumentLoadFailureOtherErrors);
    }
};

export const onLoadModelError = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'onLoadModelError() [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    errorMessage(locale[lang].onLoadModelError);
};

export const addModel = (urn, viewer) => {
    return new Promise((resolve, reject) => {
        window.Autodesk.Viewing.Document.load(
            `urn:${urn.replace('_', '/')}`,
            (doc) => {
                const geometries = doc.getRoot().search({ type: 'geometry' });
                const initGeom = geometries[0];
                const svfUrl = doc.getViewablePath(initGeom);
                viewer?.loadModel(
                    svfUrl,
                    {
                        createWireframe: false,
                        // applyScaling: 'm',
                        isAEC: true,
                        globalOffset: viewer?.model?.getGlobalOffset(),
                        acmSessionId: doc.getAcmSessionId(svfUrl),
                        // applyRefPoint: true,
                        skipHiddenFragments: true,
                    },
                    (model) => resolve(model),
                    onLoadModelError
                );
                if (viewer === undefined || viewer === null) reject();
            }
        );
    });
};

export const removeModel = (model, viewer) => {
    viewer?.unloadModel(model);
};

export const getAllLeafComponents = (viewer, callback) => {
    try {
        if (viewer)
            viewer?.getObjectTree(function (tree) {
                let leaves = [];
                tree.enumNodeChildren(
                    tree.getRootId(),
                    function (dbId) {
                        if (tree.getChildCount(dbId) === 0) {
                            leaves.push(dbId);
                        }
                    },
                    true
                );

                callback(leaves);
            });
    } catch (err) {
        console.log(err);
    }
};

////////////////////////////////////////////////////////
/// Get Project Base Point of the Model (REVIT ONLY) ///
////////////////////////////////////////////////////////
export const getProjectBasePoint = async (model) => {
    return new Promise(async (resolve, reject) => {
        try {
            const basePointData = await getBasePointData(model);

            const eastWestProp = basePointData?.properties?.find(
                (p) => p.attributeName === 'E/W'
            );
            const northSouthProp = basePointData?.properties?.find(
                (p) => p.attributeName === 'N/S'
            );
            const elevProp = basePointData?.properties?.find(
                (p) => p.attributeName === 'Elev'
            );
            // const angletonProp = basePointData.properties.find(
            //     (p) => p.attributeName === 'Angle to True North'
            // );

            const eastWestVal =
                window.Autodesk.Viewing.Private.convertToDisplayUnits(
                    eastWestProp?.displayValue,
                    eastWestProp?.type,
                    eastWestProp?.units,
                    model?.getUnitString()
                );
            const northSouthVal =
                window.Autodesk.Viewing.Private.convertToDisplayUnits(
                    northSouthProp?.displayValue,
                    northSouthProp?.type,
                    northSouthProp?.units,
                    model?.getUnitString()
                );
            const elevVal =
                window.Autodesk.Viewing.Private.convertToDisplayUnits(
                    elevProp?.displayValue,
                    elevProp?.type,
                    elevProp?.units,
                    model?.getUnitString()
                );

            const basePoint = new window.THREE.Vector3(
                eastWestVal?.displayValue,
                northSouthVal?.displayValue,
                elevVal?.displayValue
            );

            resolve(basePoint);
        } catch (err) {
            reject(err);
        }
    });
};
export const getProjectBasePointPosition = async (model, basePoint) => {
    const projectLocationToModelTransformation =
        await getProjectLocationToModelTransformation(model);

    return basePoint.clone().applyMatrix4(projectLocationToModelTransformation);
};
const getProjectLocationToModelTransformation = async (model) => {
    const aecModelData = await window.Autodesk.Viewing.Document.getAecModelData(
        model.getDocumentNode()
    );
    const refPointTransformation = readMatrixFromArray12(
        aecModelData.refPointTransformation
    );

    const projectLocationToModelTransformation = new SylvesterMatrix(
        refPointTransformation.elements
    )
        .inverse()
        .toThreeMatrix4();
    return projectLocationToModelTransformation;
};
const readMatrixFromArray12 = (params) => {
    return new window.Autodesk.Viewing.Private.LmvMatrix4(true).fromArray([
        params[0],
        params[1],
        params[2],
        0.0,
        params[3],
        params[4],
        params[5],
        0.0,
        params[6],
        params[7],
        params[8],
        0.0,
        params[9],
        params[10],
        params[11],
        1.0, // Note that the 1 is essential - otherwise multiplying with translations has no effect!
    ]);
};
const getBasePointData = async (model, category = 'Revit Base Point') => {
    return new Promise(async (resolve, reject) => {
        try {
            const found = await searchAsync(model, category, ['Category'], {
                searchHidden: true,
            });
            if (!found || found.length <= 0)
                return reject('Base point not found');

            const result = await getBulkProperties2Async(
                found,
                { propFilter: ['N/S', 'E/W', 'Elev', 'Angle to True North'] },
                model
            );

            if (!result) return reject('Base point not found');

            const data = result[0];
            return resolve(data);
        } catch (err) {
            reject(err);
        }
    });
};
const searchAsync = async (model, text, attributeNames, options) => {
    return new Promise((resolve, reject) => {
        model?.search(text, resolve, reject, attributeNames, options);
    });
};
const getBulkProperties2Async = async (dbIds, options, model) => {
    return new Promise((resolve, reject) => {
        model?.getBulkProperties2(
            dbIds,
            options,
            (result) => resolve(result),
            (error) => reject(error)
        );
    });
};
let Sylvester = {};
Sylvester.Matrix = function () {};
Sylvester.Matrix.create = function (elements) {
    var M = new Sylvester.Matrix();
    return M.setElements(elements);
};
Sylvester.Matrix.I = function (n) {
    var els = [],
        i = n,
        j;
    while (i--) {
        j = n;
        els[i] = [];
        while (j--) {
            els[i][j] = i === j ? 1 : 0;
        }
    }
    return Sylvester.Matrix.create(els);
};
Sylvester.Matrix.prototype = {
    dup: function () {
        return Sylvester.Matrix.create(this.elements);
    },

    isSquare: function () {
        var cols = this.elements.length === 0 ? 0 : this.elements[0].length;
        return this.elements.length === cols;
    },

    toRightTriangular: function () {
        if (this.elements.length === 0) return Sylvester.Matrix.create([]);
        var M = this.dup(),
            els;
        var n = this.elements.length,
            i,
            j,
            np = this.elements[0].length,
            p;
        for (i = 0; i < n; i++) {
            if (M.elements[i][i] === 0) {
                for (j = i + 1; j < n; j++) {
                    if (M.elements[j][i] !== 0) {
                        els = [];
                        for (p = 0; p < np; p++) {
                            els.push(M.elements[i][p] + M.elements[j][p]);
                        }
                        M.elements[i] = els;
                        break;
                    }
                }
            }
            if (M.elements[i][i] !== 0) {
                for (j = i + 1; j < n; j++) {
                    var multiplier = M.elements[j][i] / M.elements[i][i];
                    els = [];
                    for (p = 0; p < np; p++) {
                        // Elements with column numbers up to an including the number of the
                        // row that we're subtracting can safely be set straight to zero,
                        // since that's the point of this routine and it avoids having to
                        // loop over and correct rounding errors later
                        els.push(
                            p <= i
                                ? 0
                                : M.elements[j][p] -
                                      M.elements[i][p] * multiplier
                        );
                    }
                    M.elements[j] = els;
                }
            }
        }
        return M;
    },

    determinant: function () {
        if (this.elements.length === 0) {
            return 1;
        }
        if (!this.isSquare()) {
            return null;
        }
        var M = this.toRightTriangular();
        var det = M.elements[0][0],
            n = M.elements.length;
        for (var i = 1; i < n; i++) {
            det = det * M.elements[i][i];
        }
        return det;
    },

    isSingular: function () {
        return this.isSquare() && this.determinant() === 0;
    },

    augment: function (matrix) {
        if (this.elements.length === 0) {
            return this.dup();
        }
        var M = matrix.elements || matrix;
        if (typeof M[0][0] === 'undefined') {
            M = Sylvester.Matrix.create(M).elements;
        }
        var T = this.dup(),
            cols = T.elements[0].length;
        var i = T.elements.length,
            nj = M[0].length,
            j;
        if (i !== M.length) {
            return null;
        }
        while (i--) {
            j = nj;
            while (j--) {
                T.elements[i][cols + j] = M[i][j];
            }
        }
        return T;
    },

    inverse: function () {
        if (this.elements.length === 0) {
            return null;
        }
        if (!this.isSquare() || this.isSingular()) {
            return null;
        }
        var n = this.elements.length,
            i = n,
            j;
        var M = this.augment(Sylvester.Matrix.I(n)).toRightTriangular();
        var np = M.elements[0].length,
            p,
            els,
            divisor;
        var inverse_elements = [],
            new_element;
        // Sylvester.Matrix is non-singular so there will be no zeros on the
        // diagonal. Cycle through rows from last to first.
        while (i--) {
            // First, normalise diagonal elements to 1
            els = [];
            inverse_elements[i] = [];
            divisor = M.elements[i][i];
            for (p = 0; p < np; p++) {
                new_element = M.elements[i][p] / divisor;
                els.push(new_element);
                // Shuffle off the current row of the right hand side into the results
                // array as it will not be modified by later runs through this loop
                if (p >= n) {
                    inverse_elements[i].push(new_element);
                }
            }
            M.elements[i] = els;
            // Then, subtract this row from those above it to give the identity matrix
            // on the left hand side
            j = i;
            while (j--) {
                els = [];
                for (p = 0; p < np; p++) {
                    els.push(
                        M.elements[j][p] - M.elements[i][p] * M.elements[j][i]
                    );
                }
                M.elements[j] = els;
            }
        }
        return Sylvester.Matrix.create(inverse_elements);
    },

    setElements: function (els) {
        var i,
            j,
            elements = els.elements || els;
        if (elements[0] && typeof elements[0][0] !== 'undefined') {
            i = elements.length;
            this.elements = [];
            while (i--) {
                j = elements[i].length;
                this.elements[i] = [];
                while (j--) {
                    this.elements[i][j] = elements[i][j];
                }
            }
            return this;
        }
        var n = elements.length;
        this.elements = [];
        for (i = 0; i < n; i++) {
            this.elements.push([elements[i]]);
        }
        return this;
    },

    toThreeMatrix4: function () {
        return new window.Autodesk.Viewing.Private.LmvMatrix4(true).fromArray(
            this.elements.flat()
        );
    },
};
class SylvesterMatrix {
    constructor(elements) {
        this.elements = elements;

        const data = [
            [
                this.elements[0],
                this.elements[1],
                this.elements[2],
                this.elements[3],
            ],
            [
                this.elements[4],
                this.elements[5],
                this.elements[6],
                this.elements[7],
            ],
            [
                this.elements[8],
                this.elements[9],
                this.elements[10],
                this.elements[11],
            ],
            [
                this.elements[12],
                this.elements[13],
                this.elements[14],
                this.elements[15],
            ],
        ];

        this.m = Sylvester.Matrix.create(data);
    }

    determinant() {
        return this.m.determinant();
    }

    inverse() {
        return this.m.inverse();
    }

    toThreeMatrix4() {
        return this.m.toThreeMatrix4();
    }
}
////////////////////////////////////////////////////////

//////////////////////////////////////
/// MODEL Transparency and Opacity ///
//////////////////////////////////////
export const handleTransparency = (viewer, dbIds, elements, value) => {
    var fragList = viewer?.model?.getFragmentList();
    viewer?.model?.unconsolidate();
    dbIds
        .filter((i) => !elements.includes(i))
        .forEach((dbId) => {
            const fragIds = nodeIdToFragIds(viewer?.model, dbId);
            fragIds.forEach((fragId) => {
                let material = fragList?.getMaterial(fragId).clone();
                if (material) {
                    material.opacity = value;
                    material.transparent = true;
                    material.needsUpdate = true;
                }
                viewer?.impl
                    .matman()
                    .addMaterial('myCustomMaterial-' + fragId, material, true);
                viewer?.model?.getFragmentList().setMaterial(fragId, material);

                viewer?.impl?.invalidate(true);
            });
        });
};
export const overrideOpacity = (viewer, dbIds) => {
    const fragList = viewer?.model?.getFragmentList();

    dbIds.forEach((dbId) => {
        const fragIds = nodeIdToFragIds(viewer?.model, dbId);

        fragIds.forEach((fragId) => {
            const material = fragList?.getMaterial(fragId);

            if (material) {
                material.opacity = 0.1;
                material.transparent = true;
                material.needsUpdate = true;
            }
        });
    });
    viewer?.impl?.invalidate(true);
    viewer?.model?.unconsolidate();

    return true;
};
const nodeIdToFragIds = (model, nodeId) => {
    var instanceTree = model?.getData()?.instanceTree;

    var fragIds = [];
    instanceTree?.enumNodeFragments(nodeId, (fragId) => {
        fragIds = [...fragIds, fragId];
    });

    return fragIds;
};
//////////////////////////////////////

//////////////////////////////////////
/// Compare Point Cloud with Model ///
//////////////////////////////////////
export const startAnalysis = (viewer, name, modelId) => {
    return new Promise(async (resolve, reject) => {
        // const instanceTree = viewer?.model?.getData()?.instanceTree;
        let meshes = [];

        getAllLeafComponents(viewer, (dbIds) => {
            viewer?.model?.getPropertySetAsync(dbIds).then(async (a) => {
                dbIds = Object.entries(a.map)
                    .find(([, p]) => p[0].displayName === 'Category')[1]
                    .filter(
                        (e) =>
                            e.displayValue !== 'Revit Level' &&
                            !e.displayValue.includes('Line')
//&&
//                            !e.displayValue.includes('Specialty Equipment')
                    )
                    .map((e) => e.dbId);

                for (let i = 0; i < dbIds.length; i++) {
                    const dbid = dbIds[i];
                    // const nodeName = instanceTree?.getNodeName(dbid);
                    // if (nodeName?.includes('[')) {
                    const fragIds = await getFragIds(viewer?.model, dbid);
                    meshes = [...meshes].concat(
                        fragIds.map((fragId) => {
                            return getMeshFromRenderProxy(
                                viewer?.impl?.getRenderProxy(
                                    viewer?.model,
                                    fragId
                                )
                            );
                        })
                    );
                    // }
                }

                const globalOffset = viewer?.model?.getGlobalOffset();
                const projectBasePoint = viewer.projectBasePoint;
                const totalOffset = {
                    x: globalOffset?.x + projectBasePoint?.x,
                    y: globalOffset?.y + projectBasePoint?.y,
                    z: globalOffset?.z + projectBasePoint?.z,
                };
                const angle =
                    viewer?.model?.getData().metadata['custom values']
                        .angleToTrueNorth *
                    (Math.PI / 180);
                const projectBasePointPosition =
                    viewer?.projectBasePointPosition;

                // analyzePointClouds(viewer, name, totalOffset, meshes);

                const total = Math.ceil(meshes.length / 500); // 500 -> to prevent json.stringify RangeError

                for (let index = 0; index < total; index++) {
                    const start = index * 500;
                    const end = Math.min((index + 1) * 500, meshes.length);

                    const chunk = meshes.slice(start, end);

                    const body = {
                        chunk,
                        total,
                        name,
                    };

                    if (index === total - 1) {
                        body.globalOffset = globalOffset;
                        body.totalOffset = totalOffset;
                        body.angle = angle;
                        body.projectBasePointPosition =
                            projectBasePointPosition;
                    }

                    try {
                        await analyzePointCloud(body, modelId, index);
                    } catch (err) {
                        reject(err);
                        break;
                    }
                }

                resolve();
            });
        });
    });
};
const getMeshFromRenderProxy = (render_proxy) => {
    let matrix = render_proxy?.matrixWorld;
    let geometry = render_proxy?.geometry;
    let attributes = geometry?.attributes;

    let vA = new window.THREE.Vector3();
    let vB = new window.THREE.Vector3();
    let vC = new window.THREE.Vector3();

    let geo = new window.THREE.Geometry();
    let iv = 0;

    if (attributes?.index !== undefined) {
        let indices = attributes?.index.array || geometry?.ib;
        let positions = geometry?.vb
            ? geometry?.vb
            : attributes?.position.array;
        let stride = geometry?.vb ? geometry?.vbstride : 3;
        let offsets = geometry?.offsets;
        if (!offsets || offsets.length === 0) {
            offsets = [{ start: 0, count: indices.length, index: 0 }];
        }
        for (let oi = 0, ol = offsets.length; oi < ol; ++oi) {
            let start = offsets[oi].start;
            let count = offsets[oi].count;
            let index = offsets[oi].index;
            for (let i = start, il = start + count; i < il; i += 3) {
                let a = index + indices[i];
                let b = index + indices[i + 1];
                let c = index + indices[i + 2];

                vA.fromArray(positions, a * stride);
                vB.fromArray(positions, b * stride);
                vC.fromArray(positions, c * stride);

                vA.applyMatrix4(matrix);
                vB.applyMatrix4(matrix);
                vC.applyMatrix4(matrix);

                geo.vertices.push(new window.THREE.Vector3(vA.x, vA.y, vA.z));
                geo.vertices.push(new window.THREE.Vector3(vB.x, vB.y, vB.z));
                geo.vertices.push(new window.THREE.Vector3(vC.x, vC.y, vC.z));
                geo.faces.push(new window.THREE.Face3(iv, iv + 1, iv + 2));
                iv = iv + 3;
            }
        }
    }

    geo.computeFaceNormals();
    geo.computeVertexNormals();
    geo.computeBoundingBox();

    const bGeo = new window.THREE.BufferGeometry().fromGeometry(geo);
    bGeo.computeFaceNormals();
    bGeo.computeVertexNormals();
    bGeo.computeBoundingBox();

    let mat = new window.THREE.MeshBasicMaterial({
        color: 0xffff00,
        side: window.THREE.DoubleSide,
    });

    let mesh = new window.THREE.Mesh(bGeo, mat);

    // mesh.matrixWorldNeedsUpdate = true;
    // mesh.matrixAutoUpdate = false;
    // mesh.frustumCulled = false;

    return mesh;
};
const getFragIds = (model, dbIds) => {
    return new Promise(async (resolve, reject) => {
        try {
            const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];

            const instanceTree = model?.getData()?.instanceTree;

            const leafIds = await getLeafNodes(model, dbIdArray);

            let fragIds = [];

            for (var i = 0; i < leafIds.length; ++i) {
                instanceTree?.enumNodeFragments(leafIds[i], (fragId) => {
                    fragIds.push(fragId);
                });
            }

            return resolve(fragIds);
        } catch (ex) {
            return reject(ex);
        }
    });
};
const getLeafNodes = (model, dbIds) => {
    return new Promise((resolve, reject) => {
        try {
            const instanceTree = model?.getData()?.instanceTree;

            dbIds = dbIds || instanceTree?.getRootId();

            const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];

            let leafIds = [];

            const getLeafNodesRec = (id) => {
                var childCount = 0;

                instanceTree?.enumNodeChildren(id, (childId) => {
                    getLeafNodesRec(childId);

                    ++childCount;
                });

                if (childCount === 0) {
                    leafIds.push(id);
                }
            };

            for (var i = 0; i < dbIdArray.length; ++i) {
                getLeafNodesRec(dbIdArray[i]);
            }

            return resolve(leafIds);
        } catch (ex) {
            return reject(ex);
        }
    });
};
//////////////////////////////////////

////////////////////////
/// Restore Sections ///
////////////////////////
export const restoreSectionBox = (viewer, cutplanes) => {
    let box = new window.THREE.Box3();
    for (const cutplane of cutplanes) {
        const normal = new window.THREE.Vector3(
            cutplane[0],
            cutplane[1],
            cutplane[2]
        );
        const offset = cutplane[3];
        const pointOnPlane = normal.clone().negate().multiplyScalar(offset);
        box.expandByPoint(pointOnPlane);
    }
    const sectionExt = viewer.getExtension('Autodesk.Section');
    sectionExt.setSectionBox(box);
};

export const restoreSectionPlane = (viewer, cutplane) => {
    const normal = new window.THREE.Vector3(
        cutplane[0],
        cutplane[1],
        cutplane[2]
    );
    const offset = cutplane[3];
    const pointOnPlane = normal.clone().negate().multiplyScalar(offset);

    const sectionExt = viewer.getExtension('Autodesk.Section');
    sectionExt.setSectionPlane(normal, pointOnPlane);
};
////////////////////////////////////////////////////////

//////////////////////////////////
/// Create Custom Toolbar Item ///
//////////////////////////////////
export const createToolbar = (toolbarName) => {
    return new window.Autodesk.Viewing.UI.ToolBar(
        toolbarName
    );
}
export const createToolbarGroup = (toolbarGroupName) => {
    return new window.Autodesk.Viewing.UI.ControlGroup(
            toolbarGroupName
        );
}
export const createButton = (buttonName, event, icon, tooltip) => {
    var customButton = new window.Autodesk.Viewing.UI.Button(
        buttonName
    );
    customButton.onClick = event;

    customButton.container.children[0].innerHTML = icon;
    customButton.setToolTip(tooltip);
    
    return customButton
}
export const createComboButton = (buttonName, icon, tooltip) => {
    var comboButton = new window.Autodesk.Viewing.UI.ComboButton(
        `${buttonName}Button`
    );

    const div = document.createElement('div');
    div.classList.add('w-full', 'h-full', 'flex', 'justify-center');
    div.innerHTML = icon;
    div.children[0].classList.add('self-center');

    comboButton.container.children[2].appendChild(div);
    comboButton.setToolTip(tooltip);

    return comboButton;
};
export const IcHidden = `
    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2581_14516)">
<path d="M12.1085 7.89185L7.89185 12.1085C7.33269 11.5494 7.01855 10.791 7.01855 10.0002C7.01855 9.60863 7.09568 9.22091 7.24552 8.85917C7.39536 8.49742 7.61498 8.16873 7.89185 7.89185C8.16873 7.61498 8.49742 7.39536 8.85917 7.24552C9.22091 7.09568 9.60863 7.01855 10.0002 7.01855C10.791 7.01855 11.5494 7.33269 12.1085 7.89185Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.8499 4.8084C13.3915 3.7084 11.7249 3.1084 9.99987 3.1084C7.0582 3.1084 4.31654 4.84173 2.4082 7.84173C1.6582 9.01673 1.6582 10.9917 2.4082 12.1667C3.06654 13.2001 3.8332 14.0917 4.66654 14.8084M7.01654 16.2751C7.96654 16.6751 8.97487 16.8917 9.99987 16.8917C12.9415 16.8917 15.6832 15.1584 17.5915 12.1584C18.3415 10.9834 18.3415 9.0084 17.5915 7.8334C17.3165 7.40006 17.0165 6.99173 16.7082 6.6084" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9248 10.5837C12.8119 11.165 12.5279 11.6993 12.1092 12.118C11.6905 12.5367 11.1561 12.8208 10.5748 12.9337M7.8915 12.1087L1.6665 18.3337M18.3332 1.66699L12.1082 7.89199" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2581_14516">
<rect width="20" height="20" fill="currentColor"/>
</clipPath>
</defs>
</svg>
            `;
export const IcShow = `
    <svg width="24" height="24" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3168 9.99993C13.3168 11.6499 11.9834 12.9833 10.3334 12.9833C8.68343 12.9833 7.3501 11.6499 7.3501 9.99993C7.3501 8.34993 8.68343 7.0166 10.3334 7.0166C11.9834 7.0166 13.3168 8.34993 13.3168 9.99993Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.3334 16.8913C13.275 16.8913 16.0167 15.1579 17.925 12.1579C18.675 10.9829 18.675 9.00794 17.925 7.83294C16.0167 4.83294 13.275 3.09961 10.3334 3.09961C7.3917 3.09961 4.65003 4.83294 2.7417 7.83294C1.9917 9.00794 1.9917 10.9829 2.7417 12.1579C4.65003 15.1579 7.3917 16.8913 10.3334 16.8913Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`
